import React from 'react';
import type { FC } from 'react';
import { Link, graphql } from 'gatsby';
import { Layout } from '../components/layout';
// eslint-disable-next-line import/no-unresolved
import { ChemotherapyPageQuery } from 'graphql-types';
import { SEO } from '../components/seo';

interface PageProps {
  data: ChemotherapyPageQuery;
}

const Page: FC<PageProps> = ({ data }) => (
  <Layout>
    <SEO
      title="化学療法"
      description="各種の悪性腫瘍に対する抗がん剤治療が可能です。抗がん剤感受性試験にもとづいたオーダーメイド化学療法も可能です。副作用を最小限にするような対策を実施いたします。"
      keywords="化学療法"
    />
    <article id="clinical-examination">
      <h2>化学療法 - Chemotherapy -</h2>
      <div className="skew-border"></div>
      <section>
        <p>
          リンパ腫をはじめとした血液腫瘍やその他固形腫瘍に対する化学療法（抗がん剤治療）を実施できます。
        </p>
        <p>
          副作用を未然に防ぐ処置を行い、動物になるべく負担のかからない化学療法を行うことが大事であると考えております。
        </p>
        <p>
          また動物のご家族そして我々医療従事者への抗がん剤の暴露を防ぎ、安全に使用するための指導、スタッフ教育も実施しております。
        </p>
      </section>
      <section>
        <h3>当院の化学療法の特徴</h3>
        <ul>
          <li>・各種の悪性腫瘍に対する抗がん剤治療が可能です</li>
          <li>
            ・抗がん剤感受性試験にもとづいたオーダーメイド化学療法も可能です
          </li>
          <li>・副作用を最小限にするような対策を実施いたします</li>
        </ul>
      </section>
    </article>
  </Layout>
);
export default Page;

export const query = graphql`
  query ChemotherapyPage {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
